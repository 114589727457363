<template>
  <div class="box">
    <header class="head">
      <div class="container">İletişim</div>
    </header>

    <article class="container">
      <div class="article first">
        <img src="@/assets/adress.png" alt="" />
        <div>İnönü Mahallesi.Gebze Güzeller OSB Mahallesi.Ebulfez Elçibey Sok.No:13 Gebze/KOCAELİ</div>
      </div>
      <div class="article">
        <img src="@/assets/myphone.png" alt="" />
        <div>0 (800) 621 22 79</div>
      </div>
      <div class="article">
        <img src="@/assets/myemail.png" alt="" />
        <div>Bize e-posta gönderin servis@tr.vivo.com</div>
      </div>
    </article>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'contact',
  created() {},
  data() {
    return {}
  },
  methods: {},
}
</script>
<style scoped lang="less">
.box {
  // min-height: calc(100% - 362px);
  min-height: 575px;
  width: 100%;
}
.head {
  background: #242933;
  height: 60px;
  div {
    text-align: left;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 60px;
  }
}
.article {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  width: 338px;
  word-wrap: break-word;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
}
.first {
  margin-top: 120px;
}
</style>
